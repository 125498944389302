<template lang="pug">
v-container
    v-progress-linear(indeterminate, absolute, v-if='loading', color='brand')
    v-row
        v-col.d-flex
            v-text-field(label='Search', color='brand', dense, outlined, append-icon='search', v-model='mmlSearchStr', @keyup='handleSearchInput', :loading='searching')
            //- v-btn(icon, color='brand')
            //-     v-icon filter_alt
        v-spacer
        v-col.flex-grow-0.flex-shrink-1.d-flex
            v-btn(text, icon, color='brand', @click='refresh', title='Refresh List')
                v-icon refresh
            v-btn.white--text(color='brand', @click='showNewDialog = true') Add New
    v-row
        v-col
            .text-h3.brand--text Master Material Lists
    v-row
        v-col
            v-data-table(:items='mmls', :headers='headers', @click:row='mmlRowClicked', :footer-props='{"items-per-page-options":[-1]}')
                template(v-slot:item.delete='{item}')
                    v-btn(text, color='red', icon, @click.stop='prompToDelete(item)')
                        v-icon delete
                template(v-slot:item.createdAt='{item, value}')
                    span {{ new Date(item.createdAt) }}

    v-dialog(v-model='showNewDialog', max-width='500')
        v-card(light)
            v-form(ref='newForm', @submit.stop='createNew')
                v-card-title Create New MML
                v-card-text
                    v-checkbox(color='brand', label='Create from existing bid', v-model='createFromTemplate')
                    v-combobox(outlined, dense, label='Bid', v-if='createFromTemplate', :items='activeBids', item-text='name', item-value='id', color='brand', @change='existingBidSelected', v-model='selectedBid')
                    v-text-field(outlined, dense, color='brand', label='Name', v-model='newMMLTemplate.name')
                v-card-actions
                    v-spacer
                    v-btn(text, @click='cancelCreateNew', :loading='creatingNew') Cancel
                    v-btn.white--text(color='brand', @click='createNew', :loading='creatingNew') Save

    v-dialog(v-model='confirmDeleteDialog', max-width='500', v-if='confirmDeleteDialog')
        v-card(light)
            v-card-title Delete MML
            v-card-text
                p Are you sure you want to delete this MML?
                p
                    span.font-italic {{ mmlToDelete.name }}
                    span  will be deleted along with all of its data.
            v-card-actions
                v-spacer
                v-btn(text, @click='cancelDelete', @loading='deleting') Cancel
                v-btn.white--text(color='red', @click='deleteMML', @loading='deleting') Delete

    v-snackbar(v-model='showAlert', dark, :timeout='3000') {{ message }}
</template>

<script>
import {debounce} from 'lodash';

export default {
    data () {
        return {
            dti,
            accessKey: 'satmmls',
            message: '',
            busy: false,

            filters: {
                close: false
            },

            createFromTemplate: false,
            newMMLTemplate: {
                name: null,
                bid: null
            },

            creatingNew: false,
            showNewDialog: false,

            mmls: [],
            mmlSearchStr: '',
            searching: false,
            loading: true,

            mmlToDelete: null,
            confirmDeleteDialog: false,
            deleting: false,

            selectedBid: null,
            activeBids: [],
            headers: [
                {
                    value: 'name',
                    text: 'Name',
                    sortable: true
                },
                {
                    value: 'jobNumber',
                    text: 'Job Number',
                    sortable: true
                },
                {
                    value: 'createdAt',
                    text: 'Created',
                    sortable: true
                },
                {
                    value: 'delete',
                    text: 'Delete'
                }
            ]
        };
    },
    methods: {
        /**
         * Gets recent MMLs to have something to populate the list with.
         */
        getData () {
            this.loading = true;
            this.socketEmit('getRecentMMLs', null, (results) => {
                results =  results.sort((a, b) => {
                    return a.name > b.name;
                });
                this.mmls = results;
                this.loading = false;
            });
            this.getActiveBids();
        },
        /**
         * Creates a new MML
         */
        async createNew () {
            if (this.createFromTemplate) {
                this.newMMLTemplate.bid = this.selectedBid.id;
            }
            this.creatingNew = true;
            const mml = await this.sendCommand({
                action: 'createNewMML',
                parameters: this.newMMLTemplate
            });
            this.creatingNew = false;
            this.showNewDialog = false;
            if (mml) {
                this.mmls.push(mml);
                this.message = 'Created new MML';
            } else {
                this.message = 'Error creating new MML';
            }
            console.log('new mmls', mml);
            this.editMML(mml.id);
        },
        /**
         * Searches for MMLs
         *
         * @param {String} searchString
         */
        search: debounce(async function (searchString) {
            const terms = searchString.toLowerCase().split(' ');
            const mmls = await this.sendCommand({
                action: 'searchMML',
                parameters: {
                    terms
                }
            });
            this.mmls = mmls;
            this.searching = false;
        }, 500),
        /**
         * Closes the new MML dialog and resets the form inputs.
         */
        cancelCreateNew () {
            this.$refs.newForm.reset();
            this.showNewDialog = false;
        },
        /**
         * Navigates to the MML edit page. The MML's id is passed.
         */
        editMML (mmlId) {
            this.$router.push({path: '/sat/editmml', query: {id: mmlId}});
        },
        /**
         * Event handler for search input.
         */
        handleSearchInput (event) {
            this.searching = true;
            this.search(event.target.value);
        },
        /**
         * Refreshes list of MMLs
         */
        refresh () {
            this.getData();
        },
        /**
         * Brings up the confirmation dialog for deleting a bid.
         */
        prompToDelete (mml) {
            this.mmlToDelete = mml;
            this.confirmDeleteDialog = true;
        },
        /**
         * Closes the confirmation dialog for deleteing a bid.
         */
        cancelDelete () {
            this.mmlToDelete = null;
            this.confirmDeleteDialog = false;
        },
        /**
         * Deletes the MML the dialog is visible for.
         */
        async deleteMML () {
            this.deleting = true;
            await this.sendCommand({
                action: 'deleteMML',
                parameters: {
                    mmlId: this.mmlToDelete.id
                }
            });
            this.confirmDeleteDialog = false;
            await this.getData();
            this.deleting = false;
        },
        /**
         * Requests bids from the server that have been marked as won (but not closed out)
         */
        async getActiveBids () {
            const bids = await this.sendCommand({
                action: 'getActiveBids',
                parameters: {}
            });
            this.activeBids = bids;
        },
        /**
         * Fired when a bid is selected. Sets the name of the MML to the bid name.
         *
         * @param {Object} newBid
         */
        existingBidSelected (newBid) {
            if (!this.newMMLTemplate.name) {
                this.newMMLTemplate.name = newBid.name;
            }
        },
        /**
         * Fired when an MML table row is clicked
         *
         * @param {Object} mml
         */
        mmlRowClicked (mml) {
            this.editMML(mml.id);
        }
    },
    computed: {
        showAlert: {
            get () {
                return this.message !== '';
            },
            set (val) {
                if (val === false) {
                    this.message = '';
                }
            }
        },
    },
    mounted () {
        this.getData();
        window.mmlsvm = this;
    }
};
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
tbody {
    tr {
        td:hover {
            cursor: pointer;
        }
    }
}
</style>
